import styled from "styled-components";
import Flex from "../../components/Flex";
import { device } from "../../styles/breakpoints";

export const AboutContainer = styled(Flex)`
  gap: 24px;
  flex-wrap: wrap;

  @media ${device.laptop} {
    flex-wrap: nowrap;
    gap: 32px;
  }
`;

export const AboutCopy = styled(Flex)`
  width: 100%;

  @media ${device.laptop} {
    width: 50%;
  }
`;

export const AboutMedia = styled(Flex)`
  h3 {
    font-size: 16px;
    color: var(--text);
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 24px;
    line-height: 24px;

    @media ${device.laptop} {
      margin-top: 32px;
      max-width: 75%;
    }
  }
`;
