import React from "react";
import {
  Accordion,
  Loading,
  Page,
  Section,
  SEO,
  Table,
} from "../../components";
import { PageTitle } from "../../components/Page/Page.styled";
import { appRoutes } from "../../routes";
import { NotFound } from "../NotFound";
import { useReferences } from "./hooks/useReferences";
import { MappedReference, ReferenceWrapper } from "./References.styled";

export type TTHeadLabel =
  | "Stadt / Gemeinde"
  | "Bundesland"
  | "Projekte"
  | "Größenordnung"
  | "Besonderheiten";

export type TTHeadValue =
  | "stadt"
  | "bundesland"
  | "projekte"
  | "size"
  | "besonderheiten"
  | "map";

const columns: {
  title: TTHeadLabel;
  field: TTHeadValue;
}[] = [
  {
    title: "Stadt / Gemeinde",
    field: "stadt",
  },
  {
    title: "Bundesland",
    field: "bundesland",
  },
  {
    title: "Projekte",
    field: "projekte",
  },
  {
    title: "Größenordnung",
    field: "size",
  },
  {
    title: "Besonderheiten",
    field: "besonderheiten",
  },
];

export const References: React.FC = () => {
  const { references, isLoading, isError } = useReferences();

  if (isError) {
    return <NotFound />;
  }

  return (
    <>
      <SEO
        pathname={appRoutes.references}
        description="Hier erhalten Sie einen umfassenden Einblick über die Referenzen der B.K.S. Stadtplanung GmbH in Trier."
        title="Referenzen"
      />
      <Page isFullPageOnly={false}>
        {isLoading ? (
          <Loading />
        ) : (
          <Section hasBackgroundColor>
            <PageTitle>Referenzen der B.K.S. Stadtplanung GmbH</PageTitle>
            <ReferenceWrapper>
              {references?.map((reference) => (
                <>
                  <Accordion
                    key={reference.id}
                    headline={reference.accordionHeadline}
                  >
                    {reference.mappedData ? (
                      <>
                        {reference.mappedData.map((mappedReference) => (
                          <MappedReference key={mappedReference.id}>
                            <h3>{mappedReference.headline}</h3>
                            <Table
                              columns={columns}
                              data={mappedReference.data}
                            />
                          </MappedReference>
                        ))}
                      </>
                    ) : (
                      <Table columns={columns} data={reference.data} />
                    )}
                  </Accordion>
                </>
              ))}
            </ReferenceWrapper>
          </Section>
        )}
      </Page>
    </>
  );
};
