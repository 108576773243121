import React from "react";
import { Link } from "react-router-dom";
import { Page, Section } from "../../components";
import { PageCopy, PageTitle } from "../../components/Page/Page.styled";
import ResponsiveIFrame from "../../components/ResponsiveIFrame";
import { SEO } from "../../components/Seo/Seo";
import { appRoutes } from "../../routes";
import { AboutContainer, AboutCopy, AboutMedia } from "./About.styled";
import { Image } from "../../components/Image";

export const AboutPage: React.FC = () => (
  <>
    <SEO
      pathname={appRoutes.about}
      description="Erfahren Sie mehr über die B.K.S. Stadtplanung GmbH"
      title="Über uns"
    />
    <Page isFullPageOnly={false}>
      <Section hasBackgroundColor>
        <AboutContainer>
          <AboutCopy flexDirection="column">
            <PageTitle>Über uns</PageTitle>
            <PageCopy>
              <p>
                Die B.K.S. Ingenieurgesellschaft für Stadtplanung, Raum- und
                Umweltplanung mbH (kurz: BKS Stadtplanung GmbH) wurde 1994 mit
                Sitz in Trier gegründet. Das Büro befasst sich seit über 20
                Jahren spezialisiert mit bauplanungsrechtlichen,
                umweltfachlichen und allen städtebaulichen Fragestellungen.
              </p>
              <p>
                Unser Arbeitsschwerpunkt liegt in der kommunalen und
                vorhabenbezogenen Bauleitplanung für öffentliche und private
                Projekte. Städte und Gemeinden sind somit gleichermaßen wie
                Projektentwickler oder Privatpersonen unsere Auftraggeber.
              </p>
              <p>
                Des Weiteren erarbeiten wir Stadt- und Ortsentwicklungskonzepte
                für Stadtteile, Quartiere oder Gemeinden. Sonderfachleute zu
                Schall- und Immissionsschutzfragen, zu Verkehr und
                Bodenwasserhaushalt sowie zum Tiefbau-Ingenieurwesen ziehen wir
                nach Anforderung im Innenverhältnis hinzu, so dass wir unseren
                Auftraggebern auf Wunsch den Planungsprozess „aus einer Hand“
                anbieten können.
              </p>
              <p>
                Unser Büro befindet sich im Zentrum von Trier, nahe Porta Nigra
                und Hauptbahnhof – hier können Sie Ihre Route zu uns planen.
              </p>
              <p>
                Der Name „BKS“ bildet die Initialien der Firmengründer ab. Mit
                gleichem Namen und gleicher Adresse führen diese in einer
                Büro-Gemeinschaft mit der BKS Stadtplanung GmbH ein
                Architekturbüro als GbR unter eigener Leitung:
                www.bks-architekten-trier.de.
              </p>
              <p>
                Wir wünschen Ihnen nun viel Spaß beim Durchblättern unserer
                Seiten. Und „im planmäßigen Fall“ geht’s hier gleich zum
                Kontakt.
              </p>
              <p>
                Herzlichst
                <br />
                Ihr <Link to={appRoutes.contact}>Thomas Lang</Link>
              </p>
            </PageCopy>
          </AboutCopy>

          <AboutMedia flexDirection="column">
            <Image
              image={{
                src: "/images/about/buero_empfang.jpeg",
                alt: "Eingangsbereich des Büros der B.K.S. Stadtplanung",
              }}
            />

            <h3>Fühlen Sie sich freundlich empfangen in unserem Büro</h3>

            <ResponsiveIFrame
              maxWidth="100%"
              src="https://www.google.com/maps/embed?pb=!1m0!4v1508320005421!6m8!1m7!1sCAoSLEFGMVFpcFB3T3Y5dnlnOVhkQ1Q3VHY0YnItNFpGdV9lVm41eUxVQW4yejlF!2m2!1d49.760466278161!2d6.6505016750889!3f326.3095643095265!4f-2.7707055071699216!5f0.7820865974627469"
              width={600}
              height={450}
              style={{ marginTop: "24px", height: "450px", width: "100%" }}
            />
          </AboutMedia>
        </AboutContainer>
      </Section>
    </Page>
  </>
);
