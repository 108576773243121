import React, { useState } from "react";
import { BackgroundImage } from "../../../../components/BackgroundImage/BackgroundImage.styled";
import {
  GalleryDescription,
  GalleryItem,
  GalleryWrapper,
} from "./ImageGallery.styled";
import Lightbox from "react-image-lightbox";

interface Props {
  gallery: {
    src: string;
    alt: string;
  }[];
}

export const ImageGallery: React.FC<Props> = ({ gallery }) => {
  const [lbIndex, setLbIndex] = useState<number>(0);
  const [isLbOpen, setIsLbOpen] = useState<boolean>(false);

  return (
    <>
      <GalleryWrapper>
        {gallery.map(({ src, alt }, index) => (
          <GalleryItem
            onClick={() => {
              setLbIndex(index);
              setIsLbOpen(true);
            }}
            itemCount={gallery.length}
          >
            <BackgroundImage url={src} />
            <GalleryDescription>{alt}</GalleryDescription>
          </GalleryItem>
        ))}
      </GalleryWrapper>

      {isLbOpen && (
        <Lightbox
          mainSrc={gallery[lbIndex].src!}
          nextSrc={gallery[(lbIndex + 1) % gallery.length].src}
          prevSrc={gallery[(lbIndex + gallery.length - 1) % gallery.length].src}
          onCloseRequest={() => setIsLbOpen(false)}
          onMovePrevRequest={() =>
            setLbIndex((lbIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() => setLbIndex((lbIndex + 1) % gallery.length)}
        />
      )}
    </>
  );
};
