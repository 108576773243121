export enum ROUTE {
  HOME = "/",
  ABOUT = "/ueber-uns",
  REFERENCES = "/referenzen",
  CONTACT = "/kontakt",
  IMPRINT = "/impressum",
  DATA_PROTECTION = "/datenschutz",
  BUILDING_PLAN = "/taetigkeitsfelder/bebauungsplan",
  AREA = "/taetigkeitsfelder/flaechennutzungsplan",
  DEVELOPMENT = "/taetigkeitsfelder/staedtebauliche-planung-stadt-und-ortsentwicklung",
  ENVIRONMENT = "/taetigkeitsfelder/umweltplanung",
  APPRAISAL = "/taetigkeitsfelder/gutachtenbegleitung",
  FIELD_OF_ACTIVITY = "/taetigkeitsfelder/",
  SINGLE_FIELD_OF_ACTIVITY = "/taetigkeitsfelder/:projectCategory",
  SPECIFIC_FOA = "/taetigkeitsfelder/:projectCategory/:id",
  EMPTY = "#",
}

export const appRoutes = {
  home: ROUTE.HOME,
  about: ROUTE.ABOUT,
  references: ROUTE.REFERENCES,
  contact: ROUTE.CONTACT,
  imprint: ROUTE.IMPRINT,
  dataProtection: ROUTE.DATA_PROTECTION,
  buildingPlan: ROUTE.BUILDING_PLAN,
  area: ROUTE.AREA,
  development: ROUTE.DEVELOPMENT,
  environment: ROUTE.ENVIRONMENT,
  appraisal: ROUTE.APPRAISAL,
  fieldOfActivity: ROUTE.FIELD_OF_ACTIVITY,
  singleFieldOfActivity: ROUTE.SINGLE_FIELD_OF_ACTIVITY,
  specificFieldOfActivity: ROUTE.SPECIFIC_FOA,
  empty: ROUTE.EMPTY,
};
