import { useState, useEffect } from "react";
import { useApi } from "../../../api/useApi";
import { TTHeadValue } from "../References";

type TReferenceData = {
  [T in TTHeadValue]: string | number | null;
}[];

type TReference = {
  id: string;
  accordionHeadline: string;
  data: TReferenceData;
  mappedData?: {
    id: string;
    headline: string;
    data: TReferenceData;
  }[];
}[];

export const useReferences = () => {
  const [references, setReferences] = useState<TReference>();
  const { data, isLoading, isError } = useApi("references");

  useEffect(() => {
    if (data) {
      setReferences(data);
    }
  }, [data]);

  return { references, isLoading, isError };
};
