export const breakpoints = {
  mobileS: '320px',
  screen320: '374px',
  mobileM: '375px',
  mobileL: '414px',
  maxTablet: '767px',
  tablet: '768px',
  laptop: '1024px',
  uxiLaptop: '1120px',
  laptopL: '1440px',
  desktop: '1880px',
  desktopL: '2560px',
}

export const device = {
  screen320: `(max-width: ${breakpoints.screen320})`,
  mobileS: `(min-width: ${breakpoints.mobileS})`,
  mobileM: `(min-width: ${breakpoints.mobileM})`,
  mobileL: `(min-width: ${breakpoints.mobileL})`,
  tablet: `(min-width: ${breakpoints.tablet})`,
  laptop: `(min-width: ${breakpoints.laptop})`,
  uxiLaptop: `(min-width: ${breakpoints.uxiLaptop})`,
  laptopL: `(min-width: ${breakpoints.laptopL})`,
  desktop: `(min-width: ${breakpoints.desktop})`,
  desktopL: `(min-width: ${breakpoints.desktop})`,
}
