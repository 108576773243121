import { useEffect, useState } from "react";
import { useApi } from "../../../api/useApi";
import { TProjectParent } from "../Project.types";

export const useProjects = (projectCategoryId: string | undefined) => {
  const [projectItems, setProjectItems] = useState<TProjectParent>();
  const [restCategories, setRestCategories] = useState<TProjectParent[]>([]);
  const { data, isLoading, isError } = useApi("categories");

  useEffect(() => {
    if (data) {
      setProjectItems(
        data.find(
          (projCategory: TProjectParent) =>
            projCategory.id === projectCategoryId
        ) ?? []
      );

      setRestCategories(
        data.filter(
          (projCategory: TProjectParent) =>
            projCategory.id !== projectCategoryId
        )
      );
    }
  }, [data, projectCategoryId]);

  return { projectItems, restCategories, isLoading, isError };
};
