import styled, { css } from "styled-components";
import { device } from "../../styles/breakpoints";
import Flex from "../Flex";

export const AccordionWrapper = styled.div<{ isOpen: boolean }>`
  align-self: flex-start;
  overflow: hidden;

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${AccordionHeadline} {
        svg {
          transform: rotate(180deg);
        }
      }

      ${AccordionContent} {
        margin-top: 32px;
        opacity: 1;
        max-height: 50000px;
      }
    `}

  & + & {
    padding-top: 32px;
    margin-top: 32px;
    border-top: 1px solid var(--brand-light);
  }
`;

export const AccordionHeadline = styled.h3`
  cursor: pointer;
  user-select: none;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--text);
  display: flex;
  align-items: center;
  width: 100%;

  span {
    width: 90%;

    @media ${device.laptop} {
      width: auto;
    }
  }

  svg {
    flex: 1;
    width: 16px;
    height: 16px;
    transition: transform 0.25s ease;

    path {
      fill: var(--brand);
    }

    @media ${device.laptop} {
      flex: unset;
      margin-left: 12px;
    }
  }
`;

export const AccordionContent = styled(Flex)`
  width: 100%;
  opacity: 0;
  margin-top: 0;
  transition: margin-top 0.25s ease, opacity 0.25s ease, max-height 0.25s ease;
  max-height: 0;
`;
