import styled from "styled-components";
import Flex from "../Flex";

export const StyledIconBox = styled(Flex)`
  font-size: 13px;
  color: var(--text);
  gap: 16px;

  h4 {
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
  }

  li {
    & + li {
      margin-top: 6px;
    }
  }
`;

export const IconContainer = styled(Flex)`
  background-color: var(--brand);
  width: 55px;
  height: 55px;
  border-radius: var(--radius-circle);
  color: var(--white);
  transition: background-color 0.25s ease;
  font-size: 22px;

  &:hover {
    background-color: var(--background-dark);
  }
`;

export const IconContainerContent = styled(Flex)`
  gap: 16px;
`;
