/**
 * Prevents the body from scrolling by applying CSS overflow properties to the body.
 * @param lock {boolean} lock - Toggle for scroll prevention.
 */
export function lockBodyScroll(lock = false) {
  if (typeof document !== "undefined") {
    document.body.style.overflow = lock ? "hidden" : "auto";
  }
}

/**
 * Checks if the a value is an empty object/collection,
 * has no enumerable properties or is any type that is not considered a collection.
 *
 * @example
 * isEmpty([]); // true
 * isEmpty({}); // true
 * isEmpty(''); // true
 * isEmpty([1, 2]); // false
 * isEmpty({ a: 1, b: 2 }); // false
 * isEmpty('text'); // false
 * isEmpty(123); // true - type is not considered a collection
 * isEmpty(true); // true - type is not considered a collection
 *
 * @param val
 */
export const isEmpty = (val: Array<any> | string | {} | null | undefined) =>
  val == null || !(Object.keys(val) || val).length;

/**
 * Returns the main path of the pathname. Used to check for the active navigation item.
 *
 * @example
 * const activeItem = getActivePath('/wissen/artikel/article-url')
 * --> output: 'wissen'
 *
 * @param pathname string
 * @returns string
 */
export function getActivePath(
  pathname: string | undefined,
  slug?: string | string[]
) {
  if (!pathname) {
    return;
  }

  // Fix for generic pages
  if (pathname === "/[slug]") {
    return slug;
  }

  return pathname.split("/")[1];
}

export function scrollTop() {
  window.scrollTo(0, 0);
}
