import React from "react";
import { FaEnvelope, FaFax, FaMapMarkerAlt, FaPhone } from "react-icons/fa";
import { Page, Section } from "../../components";
import Flex from "../../components/Flex";
import { IconBox } from "../../components/IconBox";
import { PageSubTitle, PageTitle } from "../../components/Page/Page.styled";
import ResponsiveIFrame from "../../components/ResponsiveIFrame";
import { SEO } from "../../components/Seo/Seo";
import { appRoutes } from "../../routes";
import { ContactForm } from "./components/Form";
import { IconBoxContainer } from "./Contact.styled";

export const ContactPage = () => (
  <>
    <SEO
      pathname={appRoutes.contact}
      description="Nehmen Sie Kontakt mit der B.K.S. Stadtplanung GmbH auf!"
      title="Kontakt"
    />
    <Page isFullPageOnly={false}>
      <Flex flexDirection="column">
        <ResponsiveIFrame
          maxWidth="100%"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2577.359724956741!2d6.650344!3d49.76049!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfd01ac1d27682105!2sB.K.S%20Ingenieurgesellschaft%20Stadtplanung%20Raum-%20u.%20Umweltplanung%20mbH!5e0!3m2!1sde!2sde!4v1642148257595!5m2!1sde!2sde"
          height={450}
          width={600}
          style={{ height: "600px", width: "100%" }}
        />
      </Flex>

      <Section>
        <IconBoxContainer justifyContent="space-between">
          <IconBox
            icon={<FaMapMarkerAlt />}
            topString="Adresse"
            bottomString={
              <>
                <ul>
                  <li>Maximinstraße 17B,</li>
                  <li>54292 Trier, Deutschland</li>
                </ul>
              </>
            }
          />
          <IconBox
            icon={<FaPhone />}
            topString="Telefon"
            bottomString={<a href="tel:+49(0)651147560">+49 (0) 651 147560</a>}
          />
          <IconBox
            icon={<FaFax />}
            topString="Fax"
            bottomString="+49 (0) 651 29978"
          />
          <IconBox
            icon={<FaEnvelope />}
            topString="E-Mail"
            bottomString={
              <a href="mailto:info@bks-trier.de">info@bks-trier.de</a>
            }
          />
        </IconBoxContainer>
      </Section>

      <Section hasBackgroundColor>
        <PageSubTitle>Wir freuen uns über jede Nachricht!</PageSubTitle>
        <PageTitle>Kontaktieren Sie uns</PageTitle>

        <ContactForm />
      </Section>
    </Page>
  </>
);
