import { ROUTE, appRoutes } from "../../routes";

type TNavItem = {
  href: ROUTE;
  label: string;
  children?: {
    href: ROUTE;
    label: string;
  }[];
};

export const NAV_ITEMS: TNavItem[] = [
  {
    href: appRoutes.home,
    label: "Home",
  },
  {
    href: appRoutes.about,
    label: "Über uns",
  },
  {
    href: appRoutes.empty,
    label: "Tätigkeitsfelder",
    children: [
      {
        href: appRoutes.buildingPlan,
        label: "Bebauungsplan",
      },
      {
        href: appRoutes.area,
        label: "Flächennutzungsplan",
      },
      {
        href: appRoutes.development,
        label: "Städtebauliche Planung / Stadt- und Ortsentwicklung",
      },
      {
        href: appRoutes.environment,
        label: "Umweltplanung",
      },
      {
        href: appRoutes.appraisal,
        label: "Gutachtenbegleitung",
      },
    ],
  },
  { href: appRoutes.references, label: "Referenzen" },
  {
    href: appRoutes.contact,
    label: "Kontakt",
    children: [
      {
        href: appRoutes.contact,
        label: "Kontaktformular",
      },
      {
        href: appRoutes.imprint,
        label: "Impressum",
      },
      {
        href: appRoutes.dataProtection,
        label: "Datenschutz",
      },
    ],
  },
];
