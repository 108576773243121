import styled from "styled-components";
import { device } from "../../styles/breakpoints";

export const StyledPageBanner = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--white);
  padding: 32px 16px;
  width: 100%;
  text-align: center;
  z-index: 3;

  @media ${device.laptop} {
    padding: 36px 72px;
    width: auto;
    text-align: right;
  }

  h1 {
    font-size: 16px;
    line-height: 22px;
    color: var(--brand);
    font-weight: 700;
    letter-spacing: 1;
    text-transform: uppercase;

    @media ${device.laptop} {
      font-size: 18px;
      line-height: 24px;
    }
  }

  h3 {
    margin-top: 6px;
    font-size: 13px;
    line-height: 15px;
    font-weight: 400;
    color: var(--text);

    @media ${device.laptop} {
      font-size: 14px;
      line-height: 16px;
      margin-top: 12px;
    }
  }
`;
