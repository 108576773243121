import React from "react";
import { Link } from "react-router-dom";
import { appRoutes } from "../../routes";
import { FooterInner, StyledFooter } from "./Footer.styled";

export const Footer: React.FC = () => (
  <StyledFooter>
    <FooterInner>
      © B.K.S. Stadtplanung GmbH | Alle Rechte vorbehalten.
      <Link to={appRoutes.imprint}>Impressum</Link>
      <Link to={appRoutes.dataProtection}>Datenschutz</Link>
    </FooterInner>
  </StyledFooter>
);
