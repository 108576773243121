import { useState, useEffect } from "react";

export function useMenuScroll() {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isTopPos, setIsTop] = useState(true);

  const handleScroll = () => {
    // find current scroll position
    const currentScrollPos = window.pageYOffset;

    // update isTopPos value
    setIsTop(currentScrollPos === 0);

    // set state to new scroll position
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    setIsTop(window.pageYOffset === 0);
    window.addEventListener("scroll", handleScroll);

    // remove event listener
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, handleScroll]);

  return {
    isTopPos,
  };
}
