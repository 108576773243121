import { useEffect, useState } from "react";
import { useApi } from "../../../api/useApi";
import { TSingleProject } from "../Project.types";

export const useSingleProject = (projectId: string | undefined) => {
  const [project, setProject] = useState<TSingleProject>();
  const { data, isLoading, isError } = useApi("project");

  useEffect(() => {
    if (data) {
      setProject(
        data.find((project: TSingleProject) => project.id === projectId) ?? []
      );
    }
  }, [data, projectId]);

  return { project, isLoading, isError };
};
