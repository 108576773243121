import React, { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import OffCanvasContextProvider from "../context/OffCanvasContext";
import { AboutPage, ContactPage, Homepage, Imprint } from "../domain";
import { DataProtection } from "../domain/Contact/pages/DataProtection";
import { NotFound } from "../domain/NotFound";
import { ProjectPage } from "../domain/Projects";
import { SingleProjectPage } from "../domain/Projects/SingleProject";
import { References } from "../domain/References";
import { appRoutes } from "../routes";
import ResetCSS from "../styles/reset";
import { Theme } from "../styles/theme";
import { BackgroundImage } from "./BackgroundImage/BackgroundImage.styled";
import { Loading } from "./Loading/Loading";

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <HelmetProvider>
          <OffCanvasContextProvider>
            <ResetCSS />
            <Theme />
            <Routes>
              <Route
                index
                element={
                  <>
                    <BackgroundImage
                      style={{ position: "absolute", zIndex: 2 }}
                      url="/images/home/home.jpeg"
                    />
                    <Homepage />
                  </>
                }
              />
              <Route path={appRoutes.about} element={<AboutPage />} />
              <Route path={appRoutes.references} element={<References />} />
              <Route path={appRoutes.contact} element={<ContactPage />} />
              <Route path={appRoutes.imprint} element={<Imprint />} />
              <Route
                path={appRoutes.dataProtection}
                element={<DataProtection />}
              />

              <Route
                path={appRoutes.singleFieldOfActivity}
                element={<ProjectPage />}
              />
              <Route
                path={appRoutes.specificFieldOfActivity}
                element={<SingleProjectPage />}
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </OffCanvasContextProvider>
        </HelmetProvider>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
