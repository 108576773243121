import loadable from "@loadable/component";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useOffCanvasContext } from "../../context/OffCanvasContext";
import { useMenuScroll } from "../../hooks/useMenuScroll";
import { appRoutes } from "../../routes";
import { scrollTop } from "../../utils/helper-functions";
import Burger from "../Burger";
import { Image } from "../Image";
import {
  DropdownItem,
  HeaderInner,
  Navigation,
  NavigationItem,
  StyledHeader,
  StyledNavDropdown,
} from "./Header.styled";
import { NAV_ITEMS } from "./navItems";

const LazyOffCanvas = loadable(() => import("../OffCanvas/OffCanvas"));

export const Header: React.FC<{ isTransparent?: boolean }> = ({
  isTransparent,
}) => {
  const { isTopPos } = useMenuScroll();
  const { offCanvasState } = useOffCanvasContext();
  const { pathname } = useLocation();

  return (
    <StyledHeader isTopPos={isTopPos} isTransparent={isTransparent}>
      <HeaderInner>
        <Link to={appRoutes.home} onClick={scrollTop}>
          <Image
            image={{
              src: "/bks-main.jpeg",
              alt: "Das Logo der B.K.S. Stadtplanung GmbH",
            }}
          />
        </Link>
        <Navigation>
          {NAV_ITEMS.map(({ children, href, label }, idx) => {
            return (
              <NavigationItem isActive={href === pathname} key={label}>
                <Link to={href} onClick={scrollTop}>
                  {label}
                </Link>

                {children && (
                  <StyledNavDropdown
                    isTopPos={isTopPos}
                    position={idx === NAV_ITEMS.length - 1 ? "right" : "left"}
                  >
                    {children.map(({ href, label }) => (
                      <DropdownItem key={label}>
                        <Link to={href}>{label}</Link>
                      </DropdownItem>
                    ))}
                  </StyledNavDropdown>
                )}
              </NavigationItem>
            );
          })}
        </Navigation>

        <Burger
          onClick={() => offCanvasState?.setIsOpen(!offCanvasState?.isOpen)}
          isOpen={offCanvasState?.isOpen}
        />

        <LazyOffCanvas activeItem={pathname} />
      </HeaderInner>
    </StyledHeader>
  );
};
