import React from "react";
import {
  IconContainer,
  IconContainerContent,
  StyledIconBox,
} from "./IconBox.styled";

interface Props {
  icon: React.ReactNode;
  topString: string | React.ReactNode;
  bottomString: string | React.ReactNode;
}

export const IconBox: React.FC<Props> = ({ icon, topString, bottomString }) => (
  <StyledIconBox alignItems="center">
    <IconContainer alignItems="center" justifyContent="center">
      {icon}
    </IconContainer>

    <IconContainerContent flexDirection="column">
      <h4>{topString}</h4>
      <div>{bottomString}</div>
    </IconContainerContent>
  </StyledIconBox>
);
