import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Loading } from "../../components/Loading/Loading";
import { Page } from "../../components/Page";
import { PageCopy } from "../../components/Page/Page.styled";
import { Section } from "../../components/Section";
import { SEO } from "../../components/Seo/Seo";
import { NotFound } from "../NotFound";
import { BottomNavigation as ProjectBottomNavigation } from "./components/BottomNavigation";
import { ImageGallery as ProjectGallery } from "./components/ImageGallery";
import { SideNavigation as ProjectSideNavigation } from "./components/SideNavigation";
import { useProjects } from "./hooks/useProjects";
import { useSingleProject } from "./hooks/useSingleProject";
import {
  ProjectDownloadsList,
  ProjectsContainer,
  ProjectsCopy,
  ProjectsDownloads,
  ProjectsInner,
  ProjectsOverviewWrapper,
  SingleProjectHeadline,
} from "./Projects.styled";

export const SingleProjectPage: React.FC = () => {
  const params = useParams();
  const {
    projectItems,
    restCategories,
    isLoading: isProjectsLoading,
    isError: isProjectsOverviewError,
  } = useProjects(params.projectCategory);
  const {
    project,
    isLoading: isSingleProjectLoading,
    isError: isSingleProjectError,
  } = useSingleProject(params.id);

  if (isProjectsOverviewError || isSingleProjectError) {
    return <NotFound />;
  }

  return (
    <>
      <SEO
        description="Erfahren Sie mehr über das Tätigkeitsfeld der B.K.S. Stadtplanung GmbH"
        title={project?.headline ?? "Tätigkeitsfelder"}
        pathname={`/taetigkeitsfelder/${params.projectCategory}/${params.id}`}
      />
      <Page isFullPageOnly={false}>
        {isProjectsLoading || isSingleProjectLoading ? (
          <Loading />
        ) : (
          <Section hasBackgroundColor>
            <ProjectsOverviewWrapper>
              <ProjectSideNavigation
                activeItem={params.id}
                projectItems={projectItems}
                restCategories={restCategories}
              />
              <ProjectsContainer flexDirection="column">
                {project?.gallery && (
                  <ProjectGallery gallery={project.gallery} />
                )}
                <ProjectsInner justifyContent="space-between">
                  <ProjectsCopy flexDirection="column">
                    <SingleProjectHeadline
                      dangerouslySetInnerHTML={{
                        __html: project?.headline ?? "",
                      }}
                    />

                    <PageCopy
                      dangerouslySetInnerHTML={{ __html: project?.copy ?? "" }}
                    />
                  </ProjectsCopy>

                  {project?.downloadsHeadline && project?.downloads && (
                    <>
                      <ProjectsDownloads>
                        <h3>{project.downloadsHeadline}</h3>
                        <ProjectDownloadsList flexDirection="column">
                          {project.downloads.map(({ label, file }) => (
                            <Link
                              target="_blank"
                              to={file}
                              rel="noopener noreferrer"
                              key={label}
                            >
                              <p>{label}</p>
                            </Link>
                          ))}
                        </ProjectDownloadsList>
                      </ProjectsDownloads>
                    </>
                  )}
                </ProjectsInner>
              </ProjectsContainer>
            </ProjectsOverviewWrapper>
            <ProjectBottomNavigation
              prev={project?.prev}
              next={project?.next}
              parent={project?.parentCategory}
            />
          </Section>
        )}
      </Page>
    </>
  );
};
