import React from "react";
import { StyledPageBanner } from "./PageBanner.styled";

interface Props {
  headline: string;
  label: string;
}

export const PageBanner: React.FC<Props> = ({ headline, label }) => (
  <StyledPageBanner>
    <h1>{headline}</h1>
    <h3>{label}</h3>
  </StyledPageBanner>
);
