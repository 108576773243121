import styled, { css } from "styled-components";
import { breakpoints, device } from "../../styles/breakpoints";
import { HEADER_HEIGHT } from "../../styles/constants";

export const StyledHeader = styled.header<{
  isTransparent?: boolean;
  isTopPos: boolean;
}>`
  background-color: ${({ isTransparent, isTopPos }) =>
    isTopPos && isTransparent ? "transparent" : "var(--white)"};
  color: ${({ isTopPos, isTransparent }) =>
    isTopPos && isTransparent ? "var(--white)" : "var(--text)"};
  width: 100%;
  height: ${HEADER_HEIGHT.mobile}px;
  position: absolute;
  top: 0;
  left: 0;
  transition: background-color 0.25s ease, height 0.25s ease;
  z-index: 32;

  img {
    max-height: ${HEADER_HEIGHT.mobile}px;
    transition: max-height 0.25s ease;
  }

  @media ${device.laptop} {
    position: fixed;
    height: ${HEADER_HEIGHT.desktop}px;

    img {
      max-height: ${HEADER_HEIGHT.desktop}px;
      transition: max-height 0.25s ease;
    }

    ${({ isTopPos }) =>
      !isTopPos &&
      css`
        height: 57px;

        img {
          max-height: 57px;
        }
      `}
  }

  @media ${device.desktop} {
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const HeaderInner = styled.nav`
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: ${breakpoints.desktop};
  height: 100%;

  @media ${device.laptop} {
    padding: 0 24px;
    align-items: stretch;
  }

  @media ${device.desktopL} {
    padding: 0 75px;
  }
`;

export const Navigation = styled.ul`
  list-style: none;
  margin-left: auto;
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.25s ease;

  @media ${device.laptop} {
    display: flex;
    align-items: center;
    opacity: 1;
    visibility: visible;
  }
`;

export const StyledNavDropdown = styled.ul<{
  isTopPos: boolean;
  position: "left" | "right";
}>`
  display: none;

  @media ${device.laptop} {
    padding: 14px 0 22px;
    position: absolute;
    top: ${({ isTopPos }) => (isTopPos ? HEADER_HEIGHT.desktop - 24 : 57)}px;
    width: 235px;
    border-top: 5px solid var(--brand);
    background-color: var(--white);
    box-shadow: rgb(17 22 26 / 8%) 0px 5px 5px 0px;

    ${({ position = "left" }) => {
      if (position === "left") {
        return css`
          left: 0;
        `;
      }

      if (position === "right") {
        return css`
          right: 0;
        `;
      }
    }}
  }
`;

export const DropdownItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 35px;
  color: var(--text-silent);
  transition: color 0.25s ease;
  font-size: 13px !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;
  padding: 0 24px;
  line-height: 17px;

  &:hover {
    color: var(--brand);
  }

  & + & {
    margin-top: 6px;
  }
`;

export const NavigationItem = styled.li<{ isActive?: boolean }>`
  position: relative;
  text-transform: uppercase;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;

  @media ${device.laptop} {
    &:hover {
      ${StyledNavDropdown} {
        display: block;
      }
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: var(--brand);
    `};

  & + & {
    margin-left: 24px;
    padding-left: 24px;

    &::before {
      content: "";
      width: 1px;
      height: 12px;
      background-color: currentColor;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  a {
    color: currentColor;
    transition: color 0.25s ease;

    &:hover {
      color: var(--brand);
    }
  }
`;

export const MobileNavigation = styled.ul`
  @media ${device.laptop} {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
`;

export const MobileNavigationItem = styled.li<{
  isActive?: boolean;
  isOpen?: boolean;
}>`
  color: ${({ isActive }) => (isActive ? "var(--brand)" : "var(--text)")};
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};

  svg {
    width: 13px;
    height: 13px;
    transition: transform 0.25s ease;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const MobileSubMenuContainer = styled.ul<{
  isOpen?: boolean;
}>`
  max-height: 0;
  opacity: 0;
  transition: max-height 0.25s ease, opacity 0.25s ease, margin-top 0.25s ease;
  overflow: hidden;
  margin-top: 0 !important;
  padding-left: 16px;

  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: 600px;
      opacity: 1;
      margin-top: 16px !important;

      svg {
        transform: rotate(180deg);
      }
    `}

  ${MobileNavigationItem} {
    font-size: 14px;

    @media ${device.laptop} {
      font-size: 18px;
    }
  }
`;
