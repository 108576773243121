import React from "react";
import { IFrameContainer } from "./ResponsiveIFrame.styled";
import { ResponsiveIFrameProps } from "./ResponsiveIFrame.types";

const ResponsiveIFrame: React.FC<ResponsiveIFrameProps> = React.memo(
  ({ src, maxWidth, style, width, height }) => (
    <IFrameContainer style={style} maxWidth={maxWidth}>
      <iframe
        className="bks-responsive-iframe"
        width={width}
        height={height}
        src={src}
        frameBorder="0"
        title="bks-iframe"
      />
    </IFrameContainer>
  )
);

export default ResponsiveIFrame;
