import styled, { css } from "styled-components";
import Flex from "../../../../components/Flex";
import { device } from "../../../../styles/breakpoints";

export const SideNavigationWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  gap: 26px;

  @media ${device.laptop} {
    width: 25%;
  }
`;

export const SideNavMainItem = styled(Flex)`
  width: 100%;
  padding: 12px 18px;
  background-color: var(--background-dark);
  align-items: center;
  justify-content: center;
  color: var(--white);
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
`;

export const SideNavList = styled.ul`
  margin-top: 16px;
`;

export const SideNavItem = styled.li<{ isActive?: boolean }>`
  font-size: 13px;
  line-height: 18px;
  color: ${({ isActive }) => (isActive ? "var(--brand)" : "var(--text)")};
  transition: color 0.25s ease;

  & + & {
    margin-top: 12px;
  }

  &:hover {
    color: var(--brand);
  }

  a {
    display: block;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 700;
    `}
`;
