import React from "react";
import { TTHeadLabel, TTHeadValue } from "../../domain/References";
import {
  TableWrapper,
  TableHead,
  TableContent,
  TableContentItem,
} from "./Table.styled";

interface Props {
  columns: {
    title: TTHeadLabel;
    field: TTHeadValue;
  }[];
  data: {
    [T in TTHeadValue]: string | number | null;
  }[];
}

export const Table: React.FC<Props> = ({ columns, data }) => {
  return (
    <TableWrapper>
      <TableHead>
        {columns.map(({ title }) => (
          <label key={title}>{title}</label>
        ))}
      </TableHead>

      {data.map((data, index) => (
        <TableContent key={`${data.stadt}-${index}`}>
          <TableContentItem>{data.stadt}</TableContentItem>
          <TableContentItem>{data.bundesland}</TableContentItem>
          <TableContentItem
            dangerouslySetInnerHTML={{
              __html: (data?.projekte as string) ?? "",
            }}
          />
          <TableContentItem>{data.size}</TableContentItem>
          <TableContentItem>{data.besonderheiten}</TableContentItem>
        </TableContent>
      ))}
    </TableWrapper>
  );
};
