import styled from "styled-components";
import Flex from "../../../../components/Flex";
import { PageSubTitle } from "../../../../components/Page/Page.styled";
import { device } from "../../../../styles/breakpoints";

export const ContactFormContainer = styled.form`
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    margin-top: 48px;
  }

  ${Flex} {
    width: 100%;
    flex-wrap: wrap;
    gap: 40px;

    @media ${device.laptop} {
      flex-wrap: nowrap;
    }

    & + ${Flex}.bks-contact-form-input-wrapper {
      margin-top: 40px;
    }
  }
`;

export const InputWrapper = styled(Flex)<{ fw?: boolean }>`
  flex-direction: column;
  width: 100%;
  position: relative;

  @media ${device.laptop} {
    width: ${({ fw }) => (fw ? "100%" : "50%")} !important;
  }
`;

export const StyledInput = styled.input`
  appearance: none;
  border: 1px solid var(--white);
  transition: border-color 0.25s ease;
  position: relative;
  width: 100%;
  padding: 10px 17px;
  outline: 0;
  line-height: 18px;
  background-color: #fff;
  color: var(--text);
  font-size: 13px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: var(--brand);
  }
`;

export const StyledTextArea = styled.textarea`
  appearance: none;
  margin-top: 40px;
  resize: none;
  position: relative;
  width: 100%;
  padding: 10px 17px;
  outline: 0;
  line-height: 18px;
  background-color: #fff;
  color: var(--text);
  font-size: 13px;
  border: 1px solid var(--white);
  transition: border-color 0.25s ease;

  &:focus {
    outline: none;
    border-color: var(--brand);
  }
`;

export const SubmitButton = styled.button`
  align-self: flex-start;
  height: 41px;
  padding: 22px;
  background-color: transparent;
  border: 2px solid var(--brand);
  transition: background-color 0.25s ease, color 0.25s ease;
  color: var(--brand);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  margin-top: 40px;

  &:hover {
    background-color: var(--brand);
    color: var(--white);
  }
`;

export const ErrorMessage = styled.span`
  position: absolute;
  bottom: -20px;
  left: 0;
  font-size: 11px;
  line-height: 17px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  color: #f61525;
`;

export const SuccessMessage = styled(PageSubTitle)`
  margin-top: 24px;
  text-transform: none;
  letter-spacing; unset;

  @media ${device.laptop} {
    margin-top: 48px;
  }
`;
