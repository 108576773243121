import React from "react";
import { Page, PageBanner } from "../../components";
import { SEO } from "../../components/Seo/Seo";
import { appRoutes } from "../../routes";

export const Homepage: React.FC = () => (
  <>
    <SEO
      pathname={appRoutes.home}
      description="Die B.K.S. Ingenieurgesellschaft für Stadtplanung, Raum- und Umweltplanung mbH (kurz: BKS Stadtplanung GmbH) wurde 1994 mit Sitz in Trier gegründet."
      title="Home"
    />
    <Page hasMinHeight={false} isFullPageOnly isTransparentHeader>
      <PageBanner
        headline="BKS Stadtplanung GmbH"
        label="Ihr Partner für Stadt-, Regional- und Umweltplanung"
      />
    </Page>
  </>
);
