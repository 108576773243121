import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useOffCanvasContext } from "../../context/OffCanvasContext";
import { useWindowSize } from "../../hooks/useWindowSize";
import { appRoutes } from "../../routes";
import { lockBodyScroll } from "../../utils/helper-functions";
import { MobileNavigation } from "../Header/Header.styled";
import { NAV_ITEMS } from "../Header/navItems";
import { Image } from "../Image";
import { MobileSub } from "./MobileSub";
import { OffCanvas as StyledOffCanvas } from "./OffCanvas.styled";

const OffCanvas: React.FC<{ activeItem: string }> = ({ activeItem }) => {
  const { offCanvasState } = useOffCanvasContext();
  const { isMobileAndTabletPortrait } = useWindowSize();
  lockBodyScroll(offCanvasState?.isOpen);

  useEffect(() => {
    if (!isMobileAndTabletPortrait) {
      offCanvasState?.setIsOpen(false);
    }
  }, [isMobileAndTabletPortrait, offCanvasState]);

  return (
    <StyledOffCanvas isOpen={offCanvasState?.isOpen}>
      <Link
        to={appRoutes.home}
        onClick={() => offCanvasState?.setIsOpen(false)}
      >
        <Image
          image={{
            src: "/bks-main.jpeg",
            alt: "Das Logo der B.K.S. Stadtplanung GmbH",
          }}
        />
      </Link>
      <MobileNavigation>
        {NAV_ITEMS.map(({ children, label, href }) => (
          <MobileSub
            key={label}
            activeItem={activeItem}
            navChildren={children}
            label={label}
            href={href}
          />
        ))}
      </MobileNavigation>
    </StyledOffCanvas>
  );
};

export default OffCanvas;
