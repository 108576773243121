import styled, { css } from "styled-components";
import { device } from "../../styles/breakpoints";

export const Container = styled.div`
  overflow: visible;
  margin: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  color: inherit;
  z-index: 101;
  display: block;
  width: 38px;
  height: 38px;
  padding-top: 12px;
  padding-right: 10px;
  padding-bottom: 12px;
  padding-left: 10px;
  cursor: pointer;

  @media ${device.laptop} {
    display: none;
  }
`;

export const Box = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
`;

export const Inner = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: var(--text);
  top: 50%;
  display: block;
  margin-top: 2px;
  width: 100%;
  height: 2px;

  &::before,
  &::after {
    display: block;
    content: "";
    position: absolute;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: var(--text);
    width: 100%;
    height: 2px;
  }

  &::before {
    top: -5px;
    transition: top 75ms ease 0.12s, opacity 75ms ease;
  }

  &::after {
    bottom: -5px;
    transition: bottom 75ms ease 0.12s,
      transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(45deg);
      background-color: var(--text);

      &::before {
        top: 0;
        transition: top 75ms ease, opacity 75ms ease 0.12s;
        opacity: 0;
        background-color: var(--text);
      }

      &::after {
        bottom: 0;
        transition: bottom 75ms ease,
          transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
        transform: rotate(-90deg);
        background-color: var(--text);
      }
    `}
`;
