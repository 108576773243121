import styled, { css } from "styled-components";

export const BackgroundImage = styled.div<{
  url: string;
  position?: string;
  repeat?: string;
  size?: string;
}>`
  background-image: url(${({ url }) => url ?? ""});
  width: 100%;
  height: 100%;

  ${({ position = "center 0" }) =>
    position &&
    css`
      background-position: ${position};
    `}

  ${({ repeat = "no-repeat" }) =>
    repeat &&
    css`
      background-repeat: ${repeat};
    `}

${({ size = "cover" }) =>
    size &&
    css`
      background-size: ${size};
    `}
`;
