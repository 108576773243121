import React from "react";
import { Helmet } from "react-helmet-async";

interface Props {
  title: string;
  description: string;
  pathname: string;
}

export const SEO: React.FC<Props> = ({ title, description, pathname }) => {
  const siteUrl =
    process.env.NODE_ENV === "production"
      ? "https://www.bks-trier.de"
      : "http://localhost:3000";

  const seo = {
    title: `${title} | B.K.S. Stadtplanung GmbH`,
    description: description,
    image: `${siteUrl}/bks-og.png`,
    url: `${siteUrl}${pathname || "/"}`,
  };
  return (
    <>
      <Helmet
        title={seo.title.replace(/&shy;/g, "")}
        htmlAttributes={{
          lang: "de-DE",
        }}
      >
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {seo.url && <meta property="og:url" content={seo.url} />}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}
        <meta name="twitter:card" content="summary_large_image" />
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}
        {seo.image && <meta name="twitter:image" content={seo.image} />}
      </Helmet>
    </>
  );
};
