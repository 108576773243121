import React from "react";
import { Page, Section } from "../../components";

export const NotFound: React.FC = () => (
  <Page isFullPageOnly={false}>
    <Section hasBackgroundColor>
      <p>
        Leider ist bei der Anfrage etwas schief gelaufen. Bitte versuchen Sie es
        erneut.
      </p>
    </Section>
  </Page>
);
