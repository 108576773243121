import axios from "axios";
import React, { useRef, useState } from "react";
import { useForm as useReactHookForm } from "react-hook-form";
import Flex from "../../../../components/Flex";
import {
  ContactFormContainer,
  ErrorMessage,
  InputWrapper,
  StyledInput,
  StyledTextArea,
  SubmitButton,
  SuccessMessage,
} from "./Form.styled";

interface FormState {
  submitting: boolean;
  status: {
    ok: boolean;
    msg: string;
  };
}

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  website?: string;
  message: string;
}

export const ContactForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useReactHookForm({ mode: "onSubmit" });
  const [serverState, setServerState] = useState<FormState>({
    submitting: false,
    status: { ok: false, msg: "" },
  });
  const formRef = useRef<HTMLFormElement>(null);

  const handleServerResponse = (ok: boolean, msg: string = "") => {
    if (!ok) {
      console.error(msg);
    }

    setServerState({
      submitting: false,
      status: { ok, msg },
    });
  };

  const handleOnSubmit = (data: FormData) => {
    setServerState({ submitting: true, status: { ok: false, msg: "" } });

    setTimeout(() => {
      axios({
        method: "post",
        url: `https://formspree.io/xayvngrr`,
        data,
      })
        .then((r) => {
          handleServerResponse(true);
        })
        .catch((r) => {
          handleServerResponse(false, r.response.data.error);
        });
    }, 2500);
  };

  const onSubmit = (data: FormData) => handleOnSubmit(data);

  return (
    <>
      {!serverState.submitting && !serverState.status.ok ? (
        <ContactFormContainer ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Flex className="bks-contact-form-input-wrapper">
            <InputWrapper>
              <StyledInput
                placeholder="Vorname*"
                {...register("firstName", { required: true })}
              />
              {errors.firstName && (
                <ErrorMessage>Bitte geben Sie Ihren Vornamen an.</ErrorMessage>
              )}
            </InputWrapper>
            <InputWrapper>
              <StyledInput
                placeholder="Nachname*"
                {...register("lastName", { required: true })}
              />
              {errors.lastName && (
                <ErrorMessage>Bitte geben Sie Ihren Nachnamen an.</ErrorMessage>
              )}
            </InputWrapper>
          </Flex>
          <Flex className="bks-contact-form-input-wrapper">
            <InputWrapper>
              <StyledInput
                placeholder="E-Mail*"
                {...register("email", {
                  required: "Bitte geben Sie eine E-Mail Adresse an.",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Bitte geben Sie eine gültige E-Mail Adresse an.",
                  },
                })}
              />
              {errors.email && (
                <ErrorMessage>
                  Bitte geben Sie eine gültige E-Mail Adresse an.
                </ErrorMessage>
              )}
            </InputWrapper>
            <InputWrapper>
              <StyledInput placeholder="Website" {...register("website")} />
            </InputWrapper>
          </Flex>

          <InputWrapper fw>
            <StyledTextArea
              placeholder="Ihre Nachricht"
              rows={10}
              {...register("message", { required: true })}
            />
            {errors.message && <ErrorMessage>Worum geht's?</ErrorMessage>}
          </InputWrapper>

          <SubmitButton type="submit">Anfrage absenden</SubmitButton>
        </ContactFormContainer>
      ) : (
        <SuccessMessage>
          Vielen Dank für Ihre Anfrage. Wir melden uns schnellstmöglich bei
          Ihnen zurück!
        </SuccessMessage>
      )}
    </>
  );
};
