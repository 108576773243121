import React from "react";
import { Box, Container, Inner } from "./Burger.styled";
import { BurgerProps } from "./Burger.types";

const Burger: React.FC<BurgerProps> = ({ isOpen, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Box>
        <Inner isOpen={isOpen} />
      </Box>
    </Container>
  );
};

export default Burger;
