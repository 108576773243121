import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { useMenuState } from "../../hooks/useMenuState";
import {
  AccordionContent,
  AccordionHeadline,
  AccordionWrapper,
} from "./Accordion.styled";

interface Props {
  headline: string;
}

export const Accordion: React.FC<Props> = ({ children, headline }) => {
  const accordionState = useMenuState(false);

  return (
    <AccordionWrapper isOpen={accordionState.isOpen}>
      <AccordionHeadline
        onClick={() => accordionState.setIsOpen(!accordionState.isOpen)}
      >
        <span>{headline}</span> <FaAngleDown />
      </AccordionHeadline>

      <AccordionContent>{children}</AccordionContent>
    </AccordionWrapper>
  );
};
