import useSWR from "swr";
import { fetcher } from "./fetcher";

export const useApi = (path: "categories" | "project" | "references") => {
  const { data, error } = useSWR(`/api/projects/${path}.json`, fetcher);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
