import styled from "styled-components";
import Flex from "../../components/Flex";
import { StyledIconBox } from "../../components/IconBox/IconBox.styled";
import { device } from "../../styles/breakpoints";

export const StyledContactPage = styled.div``;

export const IconBoxContainer = styled(Flex)`
  gap: 24px;

  ${StyledIconBox} {
    width: 100%;

    @media ${device.laptop} {
      width: auto;
    }
  }
`;
