import React from "react";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header";
import { StyledMain, StyledPage } from "./Page.styled";

interface Props {
  isTransparentHeader?: boolean;
  isFullPageOnly: boolean;
  hasMinHeight?: boolean;
}

export const Page: React.FC<Props> = ({
  children,
  isFullPageOnly,
  isTransparentHeader,
  hasMinHeight = false,
}) => (
  <StyledPage isFullPageOnly={isFullPageOnly}>
    <Header isTransparent={isTransparentHeader} />
    <StyledMain hasMinHeight={hasMinHeight}>{children}</StyledMain>
    <Footer />
  </StyledPage>
);
