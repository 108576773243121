import styled, { css } from "styled-components";
import { ResponsiveIFrameProps } from "./ResponsiveIFrame.types";

export const IFrameContainer = styled.div<
  Pick<ResponsiveIFrameProps, "maxWidth" | "style">
>`
  width: 100%;
  cursor: pointer;
  position: relative;
  margin: 0 auto;

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
