import React from "react";
import { SectionInner, StyledSection } from "./Section.styled";

export const Section: React.FC<{ hasBackgroundColor?: boolean }> = ({
  children,
  hasBackgroundColor,
}) => (
  <StyledSection hasBackgroundColor={hasBackgroundColor}>
    <SectionInner flexDirection="column">{children}</SectionInner>
  </StyledSection>
);
