import styled, { css } from "styled-components";
import { device } from "../../styles/breakpoints";
import Flex from "../Flex";

export const StyledSection = styled.section<{ hasBackgroundColor?: boolean }>`
  width: 100%;
  background-color: var(--white);

  ${({ hasBackgroundColor }) =>
    hasBackgroundColor &&
    css`
      background-color: var(--background);
    `}
`;

export const SectionInner = styled(Flex)`
  max-width: 1280px;
  margin: 0 auto;
  padding: 48px 16px;

  @media ${device.laptop} {
    padding: 48px 24px;
  }

  @media ${device.laptop} {
    padding: 80px 24px;
  }
`;
