import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Page, Section } from "../../components";
import { BackgroundImage } from "../../components/BackgroundImage/BackgroundImage.styled";
import { Loading } from "../../components/Loading/Loading";
import { SEO } from "../../components/Seo/Seo";
import { NotFound } from "../NotFound";
import { SideNavigation } from "./components/SideNavigation";
import { useProjects } from "./hooks/useProjects";
import {
  ProjectImage,
  ProjectItem,
  ProjectsOverview,
  ProjectsOverviewWrapper,
} from "./Projects.styled";

export const ProjectPage: React.FC = () => {
  const { projectCategory } = useParams();
  const { projectItems, restCategories, isLoading, isError } =
    useProjects(projectCategory);

  if (isError) {
    return <NotFound />;
  }

  return (
    <>
      <SEO
        description={`Erfahren Sie mehr über das Tätigkeitsfeld ${projectItems?.name} der B.K.S. Stadtplanung GmbH`}
        title={projectItems?.name ?? "Tätigkeitsfelder"}
        pathname={`/taetigkeitsfelder/${projectCategory}`}
      />
      <Page isFullPageOnly={false}>
        {isLoading ? (
          <Loading />
        ) : (
          <Section hasBackgroundColor>
            <ProjectsOverviewWrapper alignItems="flex-start">
              <SideNavigation
                projectItems={projectItems}
                restCategories={restCategories}
              />
              <ProjectsOverview>
                {projectItems?.items && (
                  <>
                    {projectItems.items.map((item) => (
                      <Link
                        to={`/taetigkeitsfelder/${projectCategory}/${item.slug}`}
                        key={item.slug}
                      >
                        <ProjectItem flexDirection="column">
                          {item.image && (
                            <ProjectImage itemCount={projectItems.items.length}>
                              <BackgroundImage url={item.image} />
                            </ProjectImage>
                          )}
                          <h3>{item.name}</h3>
                        </ProjectItem>
                      </Link>
                    ))}
                  </>
                )}
              </ProjectsOverview>
            </ProjectsOverviewWrapper>
          </Section>
        )}
      </Page>
    </>
  );
};
