import React, { createContext, useContext } from "react";
import { MenuState, useMenuState } from "../hooks/useMenuState";

interface OffCanvasState {
  offCanvasState: MenuState;
}

const OffCanvasContext = createContext<OffCanvasState | null>(null);

const OffCanvasContextProvider: React.FC = ({ children }) => {
  const offCanvasState = useMenuState(false);

  return (
    <OffCanvasContext.Provider value={{ offCanvasState }}>
      {children}
    </OffCanvasContext.Provider>
  );
};

export const useOffCanvasContext = (): Partial<OffCanvasState> => {
  const ctx = useContext(OffCanvasContext);

  if (!ctx) {
    console.warn(
      "No OffCanvasContext found in this tree. Is the OffCanvasContext available as a parent component?"
    );
  }

  return {
    ...ctx,
  };
};

export default OffCanvasContextProvider;
