import styled from "styled-components";
import { device } from "../../styles/breakpoints";

export const TableWrapper = styled.div``;

export const TableRow = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  color: #222;
  padding: 12px;

  label {
    font-size: 13px;
    line-height: 17px;
    font-weight: 700;
    color: var(--text);
  }

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
  }
`;

export const TableHead = styled(TableRow)`
  text-decoration: underline;
  border-bottom: unset;
  display: none;
  background-color: var(--brand-light);

  @media ${device.laptop} {
    display: grid;
  }
`;

export const TableContent = styled(TableRow)`
  background-color: var(--white);

  &:nth-child(odd) {
    background-color: var(--background);
  }
`;

export const TableContentItem = styled.div`
  font-size: 13px;
  line-height: 17px;
  color: var(--text);
  max-width: 94%;

  ul {
    list-style: disc;
    padding-left: 16px;
  }

  li {
    font-size: 13px;
    color: var(--text);
    line-height: 23px;
  }
`;
