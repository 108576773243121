import styled from "styled-components";
import Flex from "../../../../components/Flex";
import { device } from "../../../../styles/breakpoints";

export const BottomNavigationWrapper = styled(Flex)`
  width: 100%;
  margin-top: 24px;

  @media ${device.laptop} {
    width: calc(75% - 36px);
    align-self: flex-end;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: var(--text-silent);
      transition: fill 0.25s ease;
    }
  }

  a {
    &:hover {
      svg {
        path {
          fill: var(--brand);
        }
      }
    }
  }
`;

export const BackToParent = styled.div`
  align-self: center;
`;
