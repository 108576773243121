import styled, { css } from "styled-components";
import Flex from "../../components/Flex";
import { device } from "../../styles/breakpoints";

export const ProjectsOverviewWrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: 32px;
  width: 100%;

  @media ${device.laptop} {
    flex-wrap: nowrap;
    gap: 48px;
  }
`;

export const ProjectsContainer = styled(Flex)`
  background-color: var(--white);
  position: relative;
  width: 100%;

  @media ${device.laptop} {
    width: 75%;
  }
`;

export const ProjectsInner = styled(Flex)`
  padding: 24px;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;

  @media ${device.laptop} {
    padding: 48px;
    flex-wrap: nowrap;
  }
`;

export const ProjectsOverview = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(257px, 1fr));
  gap: 24px;
  width: 100%;

  @media ${device.laptop} {
    width: 75%;
    gap: 48px 24px;
  }
`;

export const ProjectImage = styled.div<{ itemCount: number }>`
  position: relative;
  height: 300px;
  width: 100%;

  @media ${device.laptop} {
    height: 193px;
    ${({ itemCount }) => {
      if (itemCount < 2) {
        return css`
          height: 300px;
        `;
      }

      if (itemCount === 2) {
        return css`
          height: 240px;
        `;
      }

      if (itemCount > 2) {
        return css`
          height: 193px;
        `;
      }
    }}
  }

  &::before {
    content: "";
    z-index: 1;
    opacity: 0;
    transition: opacity 0.25s ease;
    background-color: var(--background-dark);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  & + h3 {
    margin-top: 20px;
    margin-left: 16px;
  }
`;

export const ProjectItem = styled(Flex)`
  position: relative;
  max-width: 400px;

  h3 {
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 20px;
    font-weight: 700;
    color: var(--text);
    transition: color 0.25s ease;
    text-transform: uppercase;
  }

  &:hover {
    h3 {
      color: var(--brand);
    }

    ${ProjectImage} {
      &::before {
        opacity: 0.5;
      }
    }
  }
`;

export const SingleProjectHeadline = styled.h1`
  font-size: 30px;
  line-height: 36px;
  color: var(--text);
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 800;
  width: 100%;
`;

export const ProjectsCopy = styled(Flex)`
  width: 100%;

  @media ${device.laptop} {
    width: 65%;
  }
`;

export const ProjectsDownloads = styled(Flex)`
  width: 100%;
  align-self: flex-start;

  @media ${device.laptop} {
    width: 25%;
  }

  h3 {
    font-size: 13px;
    line-height: 17px;
    color: var(--text);
    text-transform: uppercase;
    font-weight: 700;
  }
`;

export const ProjectDownloadsList = styled(Flex)`
  p {
    color: var(--brand);
    font-size: 13px;
    line-height: 17px;
    margin-top: 12px;
  }
`;
