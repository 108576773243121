import React from "react";
import { FaAngleLeft, FaAngleRight, FaThLarge } from "react-icons/fa";
import { Link } from "react-router-dom";
import { appRoutes } from "../../../../routes";
import { scrollTop } from "../../../../utils/helper-functions";
import {
  BackToParent,
  BottomNavigationWrapper,
} from "./BottomNavigation.styled";

interface Props {
  prev: string | undefined;
  next: string | undefined;
  parent: string | undefined;
}

export const BottomNavigation: React.FC<Props> = ({ prev, next, parent }) => {
  return (
    <BottomNavigationWrapper justifyContent="space-between" alignItems="center">
      {prev ? (
        <Link to={prev} onClick={scrollTop}>
          <FaAngleLeft />
        </Link>
      ) : (
        <div style={{ width: "1px" }} />
      )}

      <BackToParent>
        <Link to={parent ?? appRoutes.home}>
          <FaThLarge />
        </Link>
      </BackToParent>

      {next ? (
        <Link to={next} onClick={scrollTop}>
          <FaAngleRight />
        </Link>
      ) : (
        <div style={{ width: "1px" }} />
      )}
    </BottomNavigationWrapper>
  );
};
