import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Props {
  image: {
    src: string;
    alt: string;
  };
}

export const Image: React.FC<Props> = ({ image }) => (
  <LazyLoadImage alt={image.alt} effect="blur" src={image.src} />
);
