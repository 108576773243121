import styled, { css } from "styled-components";
import { device } from "../../styles/breakpoints";
import { HEADER_HEIGHT } from "../../styles/constants";
import { renderToStaticMarkup as rtsm } from "react-dom/server";
import { FaQuoteRight } from "react-icons/fa";
import React from "react";

const quoteIcon = css`
  ${rtsm(<FaQuoteRight size={20} color="16479e" />).replace(/"/g, "'")}
`;

export const StyledPage = styled.div<{ isFullPageOnly?: boolean }>`
  width: 100%;

  ${({ isFullPageOnly = false }) =>
    isFullPageOnly &&
    css`
      height: 100vh;
    `}
`;

export const StyledMain = styled.main<{ hasMinHeight: boolean }>`
  background-color: var(--background);
  height: 100%;
  padding-top: ${HEADER_HEIGHT.mobile}px;
  position: relative;

  @media ${device.laptop} {
    padding-top: ${HEADER_HEIGHT.desktop}px;

    ${({ hasMinHeight = true }) =>
      hasMinHeight &&
      css`
        min-height: 1200px;
      `}
  }
`;

export const PageTitle = styled.h2`
  font-size: 24px;
  line-height: 28px;
  color: var(--text);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 800;
  width: 100%;

  @media ${device.laptop} {
    font-size: 30px;
    line-height: 34px;
  }
`;

export const PageSubTitle = styled.h3`
  font-size: 13px;
  line-height: 17px;
  color: var(--text);
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;

  & + ${PageTitle} {
    margin-top: 16px;

    @media ${device.laptop} {
      margin-top: 24px;
    }
  }
`;

export const PageCopy = styled.div`
  width: 100%;

  h4 {
    font-size: 12px;
    line-height: 17px;
    color: var(--text);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 16px;

    @media ${device.laptop} {
      margin-top: 24px;
    }
  }

  p {
    font-size: 13px;
    color: var(--text);
    line-height: 23px;

    & + p {
      margin-top: 16px;

      @media ${device.laptop} {
        margin-top: 24px;
      }
    }

    &:first-of-type {
      margin-top: 16px;

      @media ${device.laptop} {
        margin-top: 24px;
      }
    }
  }

  ul {
    list-style: disc;
    padding-left: 16px;
  }

  li {
    font-size: 13px;
    color: var(--text);
    line-height: 23px;
  }

  a {
    color: var(--brand);
  }

  ${PageSubTitle} {
    margin-top: 16px;

    @media ${device.laptop} {
      margin-top: 24px;
    }
  }

  div.bks-blockquote {
    font-size: 16px;
    font-family: "Georgia", sans-serif;
    font-style: italic;
    font-weight: 400;
    color: #8b8b8b;
    padding-left: 36px;
    margin-top: 24px;
    position: relative;

    @media ${device.laptop} {
      font-size: 20px;
    }

    &::before {
      content: url("data:image/svg+xml; utf8,${quoteIcon}");
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  img {
    margin-top: 24px;

    & + h5 {
      margin-top: 22px;
      font-size: 17px;
      letter-spacing: 3px;
      line-height: 24px;
      font-weight: 700;
      color: var(--text);
      text-transform: uppercase;
    }
  }
`;
