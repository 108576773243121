import styled from "styled-components";
import { TableWrapper } from "../../components/Table/Table.styled";

export const ReferenceWrapper = styled.div`
  margin-top: 48px;
`;

export const MappedReference = styled.div`
  width: 100%;

  & + & {
    margin-top: 48px;
  }

  h3 {
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 1px;
    font-weight: 700;
    color: var(--text);
    display: flex;
    align-items: center;
    width: 100%;
  }

  ${TableWrapper} {
    margin-top: 12px;
  }
`;
