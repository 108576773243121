import React from "react";
import { Section } from "../../../../components";
import { Page } from "../../../../components/Page/Page";
import { PageCopy, PageTitle } from "../../../../components/Page/Page.styled";
import { SEO } from "../../../../components/Seo/Seo";
import { appRoutes } from "../../../../routes";

export const Imprint: React.FC = () => (
  <>
    <SEO
      pathname={appRoutes.imprint}
      description="Das Impressum der B.K.S. Stadtplanung GmbH"
      title="Impressum"
    />
    <Page isFullPageOnly={false}>
      <Section hasBackgroundColor>
        <PageTitle>Impressum</PageTitle>
        <PageCopy>
          <p>
            B.K.S.
            <br />
            Ingenieurgesellschaft für Stadtplanung, Raum- und Umweltplanung mbH
            <br />
            Maximinstr. 17b
            <br />
            D-54292 Trier
          </p>
          <p>
            Telefon: <a href="tel:+49-651-147560">+49-651-147560</a>
            <br />
            Telefax : +49-651-29978
            <br />
            E-Mail: <a href="mailto:info@bks-trier.de">info@bks-trier.de</a>
            <br />
            Internet: www.bks-trier.de
          </p>
          <p>Vertretungsberechtigter Geschäftsführer: Dipl.-Ing. Thomas Lang</p>
          <p>
            Registergericht: Amtsgericht Wittlich
            <br />
            Registernummer: HRB 3400
            <br />
            Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV: Dipl.-Ing.
            Thomas Lang (Anschrift wie oben)
          </p>
          <p>
            Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle
            übernehmen wir keine Haftung für die Inhalte externer Links.
            <br />
            Für den Inhalt der verlinkten Seiten sind ausschließlich deren
            Betreiber verantwortlich.
          </p>
        </PageCopy>
      </Section>
    </Page>
  </>
);
