import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useOffCanvasContext } from "../../../context/OffCanvasContext";
import { useMenuState } from "../../../hooks/useMenuState";
import { ROUTE } from "../../../routes";
import { scrollTop } from "../../../utils/helper-functions";
import ConditionalWrapper from "../../ConditionalWrapper";
import {
  MobileNavigationItem,
  MobileSubMenuContainer,
} from "../../Header/Header.styled";

interface Props {
  navChildren?: {
    label: string;
    href: ROUTE;
  }[];
  label: string;
  href: ROUTE;
  activeItem: string;
}

export const MobileSub: React.FC<Props> = ({
  navChildren,
  label,
  href,
  activeItem,
}) => {
  const subMenuState = useMenuState(false);
  const { offCanvasState } = useOffCanvasContext();

  return (
    <MobileNavigationItem
      key={label}
      isActive={href === activeItem}
      isOpen={subMenuState?.isOpen}
      onClick={() => {
        if (navChildren) {
          subMenuState?.setIsOpen(!subMenuState?.isOpen);
          return;
        }

        offCanvasState?.setIsOpen(false);
        scrollTop();
        return;
      }}
    >
      <ConditionalWrapper
        condition={!navChildren}
        wrapper={(children) => <Link to={href}>{children}</Link>}
      >
        {label} {navChildren && <FaAngleDown />}
      </ConditionalWrapper>
      {navChildren && (
        <MobileSubMenuContainer isOpen={subMenuState?.isOpen}>
          {navChildren.map(({ label, href }) => (
            <MobileNavigationItem
              isActive={href === activeItem}
              onClick={() => {
                offCanvasState?.setIsOpen(false);
                scrollTop();
              }}
              key={label}
            >
              <Link to={href}>- {label}</Link>
            </MobileNavigationItem>
          ))}
        </MobileSubMenuContainer>
      )}
    </MobileNavigationItem>
  );
};
