import styled from "styled-components";
import { breakpoints, device } from "../../styles/breakpoints";

export const StyledFooter = styled.footer`
  width: 100%;
  height: 100px;
  background-color: var(--background-dark);

  @media ${device.laptop} {
    height: 185px;
  }
`;

export const FooterInner = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: ${breakpoints.desktop};
  color: var(--text-silent);
  height: 100%;
  margin: 0 auto;
  font-size: 13px;
  line-height: 14px;
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    padding: 0 24px;
    flex-direction: row;
  }

  a {
    display: inline-block;
    margin: 4px 0 0;

    @media ${device.laptop} {
      margin: 0;

      &::before {
        content: "|";
        margin: 0 4px;
      }
    }
  }
`;
