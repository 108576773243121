import React from "react";
import { Link } from "react-router-dom";
import Flex from "../../../../components/Flex";
import { TProjectParent } from "../../Project.types";
import {
  SideNavigationWrapper,
  SideNavItem,
  SideNavList,
  SideNavMainItem,
} from "./SideNavigation.styled";

interface Props {
  activeItem?: string;
  projectItems: TProjectParent | undefined;
  restCategories: TProjectParent[];
}

export const SideNavigation: React.FC<Props> = ({
  activeItem,
  projectItems,
  restCategories,
}) => {
  return (
    <SideNavigationWrapper>
      <Flex flexDirection="column">
        <SideNavMainItem as="aside">{projectItems?.name}</SideNavMainItem>

        <SideNavList>
          {projectItems?.items && (
            <>
              {projectItems.items.map((item) => (
                <SideNavItem
                  isActive={activeItem === item.slug}
                  key={item.slug}
                >
                  <Link
                    to={`/taetigkeitsfelder/${projectItems.id}/${item.slug}`}
                  >
                    {item.name}
                  </Link>
                </SideNavItem>
              ))}
            </>
          )}
        </SideNavList>
      </Flex>

      <Flex flexDirection="column">
        <SideNavMainItem as="aside">
          Alle weiteren Tätigkeitsfelder
        </SideNavMainItem>

        <SideNavList>
          {restCategories.map((category) => (
            <SideNavItem key={category.id}>
              <Link to={`/taetigkeitsfelder/${category.id}`}>
                {category.name}
              </Link>
            </SideNavItem>
          ))}
        </SideNavList>
      </Flex>
    </SideNavigationWrapper>
  );
};
