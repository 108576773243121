import styled, { css } from "styled-components";
import Flex from "../../../../components/Flex";
import { device } from "../../../../styles/breakpoints";

export const GalleryWrapper = styled(Flex)`
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;
  background-color: var(--background);
`;

export const GalleryDescription = styled(Flex)`
  opacity: 0;
  color: var(--text);
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  transition: opacity 0.25s ease;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  letter-spacing: 1px;
  padding: 0 12px;
  text-align: center;
  text-transform: uppercase;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

export const GalleryItem = styled.div<{ itemCount: number }>`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 200px;

  &:hover {
    ${GalleryDescription} {
      opacity: 1;
    }
  }

  @media ${device.laptop} {
    ${({ itemCount }) => {
      if (itemCount < 2) {
        return css`
          height: 300px;

          @media ${device.laptop} {
            max-width: 300px;
          }
        `;
      }
      if (itemCount === 2) {
        return css`
          height: 300px;

          @media ${device.laptop} {
            width: calc(50% - 8px);
          }
        `;
      }

      if (itemCount % 3 === 0) {
        return css`
          height: 210px;

          @media ${device.laptop} {
            width: calc(33% - 8px);
          }
        `;
      }

      if (itemCount > 3) {
        return css`
          height: 151px;

          @media ${device.laptop} {
            width: calc(25% - 12px);
          }
        `;
      }
    }}
  }
`;
