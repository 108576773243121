import styled, { css } from "styled-components";
import { device } from "../../styles/breakpoints";

export const OffCanvas = styled.aside<{ isOpen: boolean | undefined }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 101vw;
  transition: transform 250ms cubic-bezier(0.3, 0, 0.15, 1);
  will-change: transform;
  background: var(--white);
  transform: ${({ isOpen }) =>
    isOpen ? "translateX(-1%)" : "translateX(100%)"};
  z-index: 100;
  padding: 0 16px;
  padding-left: calc(1vw + 16px);
  display: flex;
  flex-direction: column;
  overflow: auto;

  img {
    opacity: 0;
    transition: opacity 0.25s ease;
    transition-delay: 0.25s;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      img {
        opacity: 1;
      }
    `}

  ul {
    margin-top: 48px;
    align-items: flex-start;
    flex-direction: column;

    li {
      font-size: 16px;
      line-height: 20px;
      opacity: 0;
      transform: translateX(20px);
      transition: transform 0.3s, opacity 0.3s;

      @media ${device.mobileL} {
        font-size: 18px;
        line-height: 22px;
      }

      ${({ isOpen }) =>
        isOpen &&
        css`
          opacity: 1;
          transform: translateX(0);
          backface-visibility: hidden;

          &:nth-child(1) {
            transition-delay: 0.25s;
          }
          &:nth-child(2) {
            transition-delay: 0.35s;
          }
          &:nth-child(3) {
            transition-delay: 0.45s;
          }
          &:nth-child(4) {
            transition-delay: 0.55s;
          }
          &:nth-child(5) {
            transition-delay: 0.65s;
          }
          &:nth-child(6) {
            transition-delay: 0.75s;
          }
          &:nth-child(7) {
            transition-delay: 0.85s;
          }
          &:nth-child(8) {
            transition-delay: 0.95s;
          }
        `}

      &:nth-child(n + 2) {
        margin-left: 0;
        margin-top: 16px;

        @media ${device.mobileL} {
          margin-top: 24px;
        }
      }
    }

    @media ${device.laptop} {
      display: none;
    }
  }
`;
