import styled, { css } from "styled-components";
import { FlexItemProps, FlexProps } from "./Flex.types";

const UXIFlex = styled.div<FlexProps>`
  align-content: ${({ alignContent }) => alignContent ?? "stretch"};
  align-items: ${({ alignItems }) => alignItems ?? "stretch"};
  display: ${({ display }) => display ?? "flex"};
  ${({ flexFlow }) =>
    !flexFlow &&
    css`
      flex-direction: ${({ flexDirection }: FlexProps) =>
        flexDirection ?? "row"};
      flex-wrap: ${({ flexWrap }) => flexWrap ?? "wrap"};
    `}
  ${({ flexFlow }) =>
    flexFlow &&
    css`
      flex-flow: ${flexFlow};
    `}
  justify-content: ${({ justifyContent }) => justifyContent ?? "flex-start"};
  width: ${({ width }) => width ?? "auto"};
`;

export const UXIFlexItem = styled.div<FlexItemProps>`
  order: ${({ order }) => order || 0};
  ${({ flex }) =>
    !flex &&
    css`
      flex-grow: ${({ flexGrow }: FlexItemProps) => flexGrow ?? 0};
      flex-shrink: ${({ flexShrink }) => flexShrink ?? 1};
      flex-basis: ${({ flexBasis }) => flexBasis ?? "auto"};
    `}
  align-self: ${({ alignSelf }) => alignSelf ?? "auto"};
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}
`;

export default UXIFlex;
