import React from "react";
import styled from "styled-components";
import { pulse } from "../../styles/animations";
import Flex from "../Flex";
import { Image } from "../Image";

const LoadingContainer = styled.div`
  background-color: var(--background);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    animation: ${pulse} 0.75s infinite;
  }
`;

export const Loading: React.FC = () => (
  <LoadingContainer>
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Image
        image={{
          src: "/bks-main.jpeg",
          alt: "Das Logo der B.K.S. Stadtplanung GmbH",
        }}
      />
    </Flex>
  </LoadingContainer>
);
