import { createGlobalStyle } from "styled-components";

export const Theme = createGlobalStyle`
    :root {
        --brand: #16479e;
        --brand-light: #d9edf7;
        --white: #fff;
        --text: #393939;
        --text-silent: #9a9a9a;
        --background: #f5f5f5;
        --background-dark: var(--text);
        --radius-circle: 50%;
    }
`;
